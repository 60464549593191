@import '@/styles/functions.scss';

.wrapper {
  --headerPadding: 12px 48px;
  --logoWidth: 130px;
  --logoHeight: 40px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--headerHeight);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--headerPadding);
  z-index: 22;
  background: linear-gradient(0deg, transparent -6.19%, var(--dark100) 100%);
  transition: background 1s ease-in-out;

  :global(.yn_button) {
    opacity: 0;
  }

  &.dark {
    background-color: var(--dark100);
    transition: background 1s ease-in-out;

    .menusWrap {
      :global(.navigationLink),
      .navigationLink {
        :global(.comp-text) {
          color: var(--white1000);
        }
      }
    }

    .contact-us {
      :global(.comp-text) {
        color: var(--white1000);
      }
    }

    .selected-lang-wrap {
      :global(.comp-text) {
        color: var(--white1000);
      }

      .language-dropdown-icon {
        color: var(--white1000);
      }
    }
  }

  &.burgerOpen {
    background-color: var(--dark100);
    transition: background var(--trTime) ease-in-out;
  }

  :global(.navigationLink),
  .navigationLink {
    position: relative;
    overflow: hidden;
    padding-bottom: spx(0.5);
    margin-top: spx(1);

    .text {
      display: block;
      transition: 0.2s;
      font-size: 11px;
      line-height: 20px;
      letter-spacing: 0.88px;
      font-weight: 450;
      font-family: var(--avenir);
    }

    &:hover {
      &::before {
        width: 100%;
        left: 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 0;
      height: 1px;
      background-color: var(--white1000);
      transition: var(--trTime);
    }

    &::after {
      opacity: 0;
    }

    &:global(.active) {
      .text {
        font-weight: 700;
      }

      &::before {
        animation: converge var(--trTime) ease-in forwards;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(50% - 3px);
        width: 6px;
        height: 1px;
        background-color: #cd7764;
        border-radius: 50%;
        animation: appear var(--trTime) ease-in forwards;
        opacity: 1;
      }
    }

    &.active {
      .text {
        font-weight: 700;
      }

      &::before {
        animation: converge var(--trTime) ease-in forwards;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(50% - 3px);
        width: 6px;
        height: 1px;
        background-color: #cd7764;
        border-radius: 50%;
        animation: appear var(--trTime) ease-in forwards;
        opacity: 1;
      }
    }

    &:last-child {
      margin-right: unset;
    }
  }
}

@keyframes converge {
  90% {
    background-color: #cd7764;
    width: 6px;
    left: calc(50% - 3px);
  }
  100% {
    opacity: 0;
    pointer-events: none;
  }
}

@keyframes appear {
  0% {
    height: 1px;
  }
  100% {
    height: 6px;
  }
}

.menusWrap {
  display: flex;
  gap: 16px;
  opacity: 0;
  flex-grow: 3;
  justify-content: center;
  padding-left: 151px;
}

.contactsWrap {
  display: flex;
  align-items: center;
  opacity: 0;

  .contact-us {
    position: relative;
    overflow: hidden;

    .text {
      padding: spx(1) 0;
      transition: 0.2s;
    }

    @media (hover: hover) {
      &:hover {
        &::after {
          width: 100%;
        }
      }
    }

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background-color: var(--royalBlue1000);
      transition: var(--trTime);
    }

    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: transparent;
    }

    &.active {
      pointer-events: none;

      &::before {
        background-color: var(--royalBlue1000);
      }
    }
  }

  .buttonsWrap {
    display: flex;
    align-items: center;
    width: 130px;
    justify-content: flex-end;
  }

  .joinWaitList {
    a {
      height: 40px;
      font-size: 12px;
      font-weight: 450;
      line-height: 20px;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }
  }
}

.logoLink {
  width: var(--logoWidth);
  cursor: pointer;
  opacity: 0;

  .logo {
    width: 74px;
    height: var(--logoHeight);
    object-fit: cover;
  }
}

.burgerWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7px;
  align-items: flex-start;
  width: 130px;
  height: spx(5);
  cursor: pointer;

  .line {
    background-color: var(--white1000);
    height: 2px;
    width: 24px;
    border-radius: 100px;
    transition: transform 0.4s ease-in-out;
  }

  &.active {
    .line1 {
      transform: rotate(45deg) translateX(25%);
    }

    .line2 {
      transform: rotate(-45deg) translateX(25%);
    }
  }
}

.burgerWrap {
  display: none;
}

.lang {
  margin-right: 8px;
  opacity: 0;
}

@media (max-width: 1440px) {
  .menusWrap {
    padding-left: 0;
  }
}

@media (max-width: 1199px) {
  .wrapper {
    :global(.yn_button) {
      display: none !important;
    }
  }
  .logoLink {
    margin: 0 auto;
    width: fit-content;
  }
  .menusWrap {
    display: none !important;
  }

  .burgerWrap {
    display: flex;
  }
}

//! Mobile
@media (max-width: 768px) {
  .wrapper {
    --headerPadding: #{spx(3)};

    justify-content: space-evenly;
    align-items: center;
  }

  .logoLink {
    margin: 0 auto;
    width: fit-content;
  }

  .menusWrap {
    display: none !important;
  }

  .contactsWrap {
    display: none;
  }

  .buttonsWrap .joinWaitList {
    display: none;
  }

  .burgerWrap {
    width: 24px;
    display: flex;
  }

  .lang {
    margin-right: 0;
    padding-right: 0 !important;
  }
}
